import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import {
    Autoplay,
    Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./galleryStandard.scss";   

interface GalleryImage {
    image: string;
    caption: string;
}

interface SwiperProps {
    images: GalleryImage[];
}

const GalleryStandard = ({images} : SwiperProps) => {
    return (
        <>
           <SwiperComponent
                pagination={{ clickable: true, el: ".pagination-progress" }}
                modules={[Pagination, Autoplay]}
                loop={true}                
                centeredSlides={true}
                slidesPerView={1}
                id='standard-swiper'
                autoplay={{ delay: 2500 }}
               
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div className="standard-image" style={{backgroundImage: `URL(${image.image})`}} />
                        <div>
                            <p className="img-caption">{image.caption}</p>
                        </div>
                    </SwiperSlide>
                ))}
                <div className='pagination-progress'></div>
            </SwiperComponent>
        </>
    )

}

export default GalleryStandard;