import React from 'react';
import Tube from '../../animations/tube/tube';

type TubeStationProps = {
    stationName: string;
    walkingDistance: string;
    tubeLines: string[];
};

const TubeStation: React.FC<TubeStationProps> = ({
    stationName,
    walkingDistance,
    tubeLines,
}) => {
    return (
        <div className="line">
            <Tube x={0} />
            <div className="copy">
                <h5>{stationName}</h5>
                <p>{walkingDistance} mins walk</p>
                <div className="tube-block">
                    {tubeLines.map((line, index) => (
                        <div key={index} className={`tube ${line.toLowerCase()}`}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TubeStation;