import Lottie from 'react-lottie';

import animation from './data.json';
import animation2 from './mask.json';
import './intro.scss';
import { motion } from 'framer-motion';

const Intro = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
    const defaultOptions2 = {
        loop: false,
        autoplay: true,
        animationData: animation2,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
    return (
        <div className='intro'>
             <Lottie
            options={defaultOptions}
            height={"100vh"}
            width={"100vw"}
            style={{
              zIndex: 2,
              position: "relative",                            
            }}
          />

               {/* <Lottie
            options={defaultOptions2}
            height={"100vh"}
            width={"100%"}
            style={{
              zIndex: 3,
              position: "absolute",
              overflowX: "hidden",
              maxWidth: "100%",
              top: 0,
              backgroundColor: 'transparent', 

            }}
          /> */}
        </div>
    );
};

export default Intro;
