import { useState } from 'react';
import './accordions.scss';
import { motion } from 'framer-motion';

interface Accordion {
    title: string;
    content: string[];
}

interface AccordionsProps {
    accordions: Accordion[];
}

const Accordions = ({ accordions }: AccordionsProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleAccordionClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className='accordion-wrapper'>
            {accordions.map((accordion, index) => {
                const isOpen = activeIndex === index;

                return (
                    <div key={index} className={`accordion ${isOpen ? 'open' : ''}`}>
                        <div className='accordion-title' onClick={() => handleAccordionClick(index)}>
                            <p>{accordion.title}</p>
                            <img src='../images/accordion.svg' />
                        </div>
                        {isOpen && (
                            <div className='accordion-content'>
                                {accordion.content.map((content, index) => {
                                    return <motion.p initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} transition={{delay: index/10}} key={index}>{content}</motion.p>;
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Accordions;