import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useRef, useState } from "react";
import Swiper from "swiper";
import './floorplan.scss';
import { motion } from "framer-motion";

interface Floorplan {
    title: string;
    btn: string;
    table: {
        unit: string;
        sqFt: string;
        sqM: string;
    }[];
}

interface FloorplanProps {
    floorplans: Floorplan[];
}

const Floorplans = ({floorplans} : FloorplanProps) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const swiperRef = useRef<Swiper | null>(null);
    
    const handleSlideChange = (swiper: Swiper) => {
        setCurrentSlide(swiper.activeIndex);
    };

    const goToSlide = (index: number) => {
        setCurrentSlide(index);
        if (swiperRef.current) {
            swiperRef.current.slideTo(index);
        }
    };

    const handleClick = (index: number) => {
        goToSlide(index);
        const element = document.getElementById(`floorplans`);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
                delay: x / 10,
            },
        },
    });
    return (
        <>
        <div className='contain-wrapper'>
                <div className='avail-two contain'>
                    <img src='../images/availability/building-diagram.svg' />
                    <div className='table-wrapper'>
                        <motion.h2
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(0)}
                            className='b'
                        >
                            Availability
                        </motion.h2>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(0)}
                            className='row border'                            
                        >
                            <div className='cell'>
                                <p>Floor</p>
                            </div>
                            <div className='cell'>
                                <p>sq ft</p>
                            </div>
                            <div className='cell'>
                                <p>sq m</p>
                            </div>
                            <div className='cell'>
                                <p>Terrace sq&nbsp;ft</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(1)}
                            className='row'
                            onClick={() => {handleClick(5)}}
                        >
                            <div className='cell'>
                                <p>Fourth</p>
                            </div>
                            <div className='cell'>
                                <p>1,063</p>
                            </div>
                            <div className='cell'>
                                <p>99</p>
                            </div>
                            <div className='cell'>
                                <p>570</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(2)}
                            className='row'
                            onClick={() => {handleClick(4)}}
                        >
                            <div className='cell'>
                                <p>Third</p>
                            </div>
                            <div className='cell'>
                                <p>2,190</p>
                            </div>
                            <div className='cell'>
                                <p>203</p>
                            </div>
                            <div className='cell'>
                                <p>581</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(3)}
                            className='row'
                            onClick={() => {handleClick(3)}}
                        >
                            <div className='cell'>
                                <p>Second</p>
                            </div>
                            <div className='cell'>
                                <p>3,186</p>
                            </div>
                            <div className='cell'>
                                <p>296</p>
                            </div>
                            <div className='cell'>
                                <p>-</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(4)}
                            className='row'
                            onClick={() => {handleClick(2)}}
                        >
                            <div className='cell'>
                                <p>First</p>
                            </div>
                            <div className='cell'>
                                <p>3,240</p>
                            </div>
                            <div className='cell'>
                                <p>301</p>
                            </div>
                            <div className='cell'>
                                <p>-</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(5)}
                            className='row'
                            onClick={() => {handleClick(1)}}
                        >
                            <div className='cell'>
                                <p>Ground</p>
                            </div>
                            <div className='cell'>
                                <p>1,044</p>
                            </div>
                            <div className='cell'>
                                <p>97</p>
                            </div>
                            <div className='cell'>
                                <p>-</p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(6)}
                            className='row no-hover'
                        >
                            <div className='cell'>
                                <p>Reception</p>
                            </div>
                            <div className='cell'>
                                <p>1,163</p>
                            </div>
                            <div className='cell'>
                                <p>108</p>
                            </div>
                            <div className='cell'>
                                <p>-</p>
                            </div>
                        </motion.div>
                        <motion.div
                    initial='initial'
                    whileInView='animate'
                    variants={variantX(7)} className='row'                             onClick={() => {handleClick(0)}}
                    >
                            <div className='cell'>
                                <p>Lower Ground</p>
                            </div>
                            <div className='cell'>
                                <p>936</p>
                            </div>
                            <div className='cell'>
                                <p>87</p>
                            </div>
                            <div className='cell'>
                                <p>-</p>
                            </div>
                        </motion.div>
                        <motion.div
                    initial='initial'
                    whileInView='animate'
                    variants={variantX(8)} className='row border'>
                            <div className='cell'>
                                <p>Total</p>
                            </div>
                            <div className='cell'>
                                <p>12,822</p>
                            </div>
                            <div className='cell'>
                                <p>1,191</p>
                            </div>
                            <div className='cell'>
                                <p>1,151</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className='contain-wrapper blue-back'>
            <div className='avail-three contain' id="floorplans">
            <div className="copy">
                <h2 className='b'>{floorplans[currentSlide].title}
                </h2>
                <div className="btns">
                {
                    floorplans.map((floorplan, index) => (
                        <a className={`btn-pill ${index == currentSlide && 'active' }`} onClick={() => goToSlide(index)}><p className="overline">{floorplans[index].btn}</p></a>
                    ))
                }
                </div>
            </div>
            <div className="table">
                {
                    floorplans[currentSlide].table.map((table, index) => (
                        <div className="row">
                            <p>{table.unit}</p>
                            <p>{table.sqFt}</p>
                            <p>{table.sqM}</p>
                        </div>
                    ))
                }
            </div>
            <SwiperComponent
                pagination={{ clickable: true, el: ".pagination-progress" }}
                modules={[Pagination, Autoplay]}                
                centeredSlides={true}
                slidesPerView={1}
                id='floorplan-swiper'
                onSlideChange={handleSlideChange}
                onSwiper={(swiper: any) => (swiperRef.current = swiper)}
                
            >
                {floorplans.map((floorplan, index) => (
                    <SwiperSlide id={`${index}`} key={index}>
                        <img
                            src={`../images/availability/floorplans/${index}.svg`}
                        />
                    </SwiperSlide>
                ))}
                <div className='pagination-progress'></div>
            </SwiperComponent>
            </div>
            </div>
        </>
    );
};

export default Floorplans;
