import { useEffect, useLayoutEffect, useState } from "react";
import Accordions from "../../components/accordions/accordions";
import Floorplans from "../../components/floorplans/floorplans";
import Footer from "../../components/footer/footer";
import { floorplanData } from "../../utils/availability";
import "./availability.scss";
import Navbar from "../../components/navbar/navbar";
import { motion } from "framer-motion";

const Availability = () => {
    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
                delay: x / 10,
            },
        },
    });

    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollTop / docHeight) * 2500;
      setBackgroundPosition(scrollPercent);
    };
  
    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <div className="avail-hero" style={{ backgroundPositionY: `-${backgroundPosition}px` }} >
            <Navbar logoGreen={true} color='#133c1e' />
            <div className='avail-one'>
                <h1 className='b'>
                    Impressive roof terraces, superior finishes, and a courtyard
                    setting
                </h1>
            </div>
            
           
        
                    <Floorplans floorplans={floorplanData} />
        
            <div className='contain-wrapper blue-back'>
                <div className='avail-four contain'>
                    <h2 className='b'>Specification</h2>
                    <div className='spec-container'>
                        <Accordions
                            accordions={[
                                {
                                    title: "THERMAL PERFORMANCE",
                                    content: [
                                        "Projected EPC rating A.",
                                    ],
                                },
                                {
                                    title: "OCCUPATIONAL DENSITY",
                                    content: ["All Building Services will be designed to 1 person per 8 sq m."],
                                },
                                {
                                    title: "RAISED FLOOR ZONES",
                                    content: ["The raised access floor zone will be a minimum of 90mm.",],
                                },
                                {
                                    title: "SOFFITS",
                                    content: ["Exposed concrete slab, services and lighting.", "MR ceiling system within core/WCs/showers.", "Suspended mesh ceiling in circulation spaces and changing rooms."],
                                },
                                {
                                    title: "FLOOR TO CEILING HEIGHTS",
                                    content: ["Basement office - 3m to u/s downstand beam.", "Ground floor office - 3.4m to u/s of downstand beam.", 'First, Second, Third floor offices - 3m to u/s of downstand beam.', 'Fourth floor office - 3m to u/s of purlins.'],
                                },
                                {
                                    title: "MEP",
                                    content: ["VRF/HVRF High Efficiency Air Conditioning Systems with heat recovery.", "14 litres per second fresh air per person, based on maximum of 1 person per 8m2 in office spaces."],
                                },
                                {
                                    title: "EXTERNAL TERRACES AND COURTYARD",
                                    content: ["107 sq m of terracess across 3rd and 4th floor and 159 sq m courtyard at ground floor."],
                                },
                            ]}
                        />
                        <Accordions
                            accordions={[
                                {
                                    title: "WC AND SHOWER PROVISION",
                                    content: [
                                        "Unisex WC at 1:8 occupancy on every floor.",
                                        "1 accessible WC per floor.",
                                        "2 WCs in end of trip facilities.",
                                        "2 male, 2 female & 1 accessible showers",
                                    ],
                                },
                                {
                                    title: "BICYCLE STORAGE",
                                    content: ["38 long stay bike spaces.", "12 long stay folding bike lockers.", "6 short stay bie spaces in the courtyard."],
                                },
                                {
                                    title: "LOCKERS",
                                    content: ["22 lockers in the female changing room.", "24 lockers in the male changing room.", "40 lockers in the locker room."],
                                },
                                {
                                    title: "ACCESS",
                                    content: ["The main pedestrian and short stay bicycle access is from Wigmore street via access controlled gates.", "Main bicycle access for long stay is via the existing car park ramp, entered from Welbeck Way."],
                                },
                                {
                                    title: "VERTICAL TRANSPORTATION",
                                    content: ["1 passenger lift. Capacity of 13 persons/1000kg.", "An aesthetically pleasing atrium stair designed to promote physical activity."],
                                },
                                {
                                    title: "WASTE",
                                    content: ["Dedicated waste and recycling storage in the basement."],
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className='contain-wrapper'>
                <div className='avail-five contain'>
                    <h2 className='b'>Professional Team</h2>
                    <div className='teams'>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(0)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/1.svg'
                                alt='team'
                            />
                            <p>Architects & Principle Designers</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(1)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/2.svg'
                                alt='team'
                            />
                            <p>
                                Project Managers
                                <br />
                                Sustainability Consultant
                                <br />
                                RoL/Daylight & Sunlight
                                <br />
                            </p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(2)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/3.svg'
                                alt='team'
                            />
                            <p>ESG Client Advisor</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(3)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/4.svg'
                                alt='team'
                            />
                            <p>Landscape Architect</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(4)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/5.svg'
                                alt='team'
                            />
                            <p>Planning</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(5)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/6.svg'
                                alt='team'
                            />
                            <p>Structural Engineer</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(6)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/7.svg'
                                alt='team'
                            />
                            <p>Quantity Surveyor</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(7)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/8.svg'
                                alt='team'
                            />
                            <p>MEPH</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(8)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/9.svg'
                                alt='team'
                            />
                            <p>Approved Inspector</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(9)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/10.svg'
                                alt='team'
                            />
                            <p>Fire Engineer</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(10)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/11.svg'
                                alt='team'
                            />
                            <p>Security Consultant</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(11)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/12.svg'
                                alt='team'
                            />
                            <p>Acoustic Consultant</p>
                        </motion.div>
                        <motion.div
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(12)}
                            className='team'
                        >
                            <img
                                src='../images/availability/teams/13.svg'
                                alt='team'
                            />
                            <p>IT/AV Consultant</p>
                        </motion.div>
                    </div>
                </div>
            </div>
            <Footer
                overline={"location"}
                copy={
                    "A premier business hub with vibrant energy and high-end appeal"
                }
                btn={"Discover the local area"}
                link={"location"}
            />
        </div>
    );
};
export default Availability;
