import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "./navbar.scss";

interface NavProps {
    color: string;
    logoGreen: boolean;
}

const Nav: React.FC<NavProps> = ({ color, logoGreen }) => {
    const [showNav, setShowNav] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showCustomNav, setShowCustomNav] = useState(false);
    const location = useLocation();
    const [showBurger, setShowBurger] = useState(false);

    const isTopInView = () => {
        const topElement = document.getElementById("top");
        if (!topElement) return false;
        const rect = topElement.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };

    const handleScroll = () => {
        const currentScrollPosition = window.scrollY;
        const isScrollingUp = currentScrollPosition < scrollPosition;

        if (currentScrollPosition > window.innerHeight && isScrollingUp) {
            setShowCustomNav(true);
        } else {
            setShowCustomNav(false);
        }

        setScrollPosition(currentScrollPosition);
    };

    useEffect(() => {
        if (isTopInView()) {
            setShowCustomNav(false);
        }
    }, [location, showNav]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollPosition]);

    const handleBurger = () => {
        setShowBurger(!showBurger);
    };

    return (
        <>
            <motion.div
                initial={{ y: -200 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.5 }}
                className='nav-wrapper'
            >
                <div className='links'>
                    <Link
                        to='/building'
                        className={`link ${
                            location.pathname === "/building" ? "active" : ""
                        }`}
                    >
                        <p style={{ color }}>BUILDING<motion.div style={{backgroundColor: `${location.pathname === "/" ? "beige" : "" }`}} className="border" ></motion.div></p>
                    </Link>
                    <Link
                        to='/availability'
                        className={`link ${
                            location.pathname === "/availability"
                                ? "active"
                                : ""
                        }`}
                    >
                        <p style={{ color }}>AVAILABILITY<motion.div style={{backgroundColor: `${location.pathname === "/" ? "beige" : "" }`}} className="border" ></motion.div></p>
                    </Link>
                    <Link
                        to='/'
                        className={`link logo ${
                            location.pathname === "/" ? "active" : ""
                        }`}
                    >
                        <img
                            src={`../images/logo${
                                logoGreen ? "-green" : ""
                            }.svg`}
                            alt='logo'
                        />
                        
                    </Link>
                    <Link
                        to='/location'
                        className={`link ${
                            location.pathname === "/location" ? "active" : ""
                        }`}
                    >
                        <p style={{ color }}>LOCATION <motion.div style={{backgroundColor: `${location.pathname === "/" ? "beige" : "" }`}} className="border" ></motion.div></p>
                       

                    </Link>
                    <Link
                        to='/contact'
                        className={`link ${
                            location.pathname === "/contact" ? "active" : ""
                        }`}
                    >
                        <p style={{ color }}>CONTACT<motion.div style={{backgroundColor: `${location.pathname === "/" ? "beige" : "" }`}} className="border" ></motion.div></p>
                        

                    </Link>
                </div>
            </motion.div>
            {showCustomNav && (
                <motion.div
                    className='custom-nav-wrapper'
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className='links'>
                        <Link
                            to='/'
                            className={`link ${
                                location.pathname === "/" ? "active" : ""
                            }`}
                        >
                            <p>HOME</p>
                        </Link>
                        <Link
                            to='/building'
                            className={`link ${
                                location.pathname === "/building"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <p>BUILDING</p>
                        </Link>
                        <Link
                            to='/availability'
                            className={`link ${
                                location.pathname === "/availability"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <p>AVAILABILITY</p>
                        </Link>
                        <Link
                            to='/location'
                            className={`link ${
                                location.pathname === "/location"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <p>LOCATION</p>
                        </Link>
                        <Link
                            to='/contact'
                            className={`link ${
                                location.pathname === "/contact" ? "active" : ""
                            }`}
                        >
                            <p>CONTACT</p>
                        </Link>
                    </div>
                </motion.div>
            )}
            <div className='mobile-burger'>
                <div onClick={() => handleBurger()}>
                    <motion.div
                        style={{
                            backgroundColor:
                                location.pathname === "/" ||
                                location.pathname === "/contact"
                                    ? "#f2ebd8"
                                    : undefined,
                        }}
                        className='line'
                        animate={{
                            rotate: showBurger ? 45 : 0,
                            y: showBurger ? 7 : 0,
                        }}
                    ></motion.div>
                    <motion.div
                        style={{
                            backgroundColor:
                                location.pathname === "/" ||
                                location.pathname === "/contact"
                                    ? "#f2ebd8"
                                    : undefined,
                        }}
                        className='line'
                        animate={{ opacity: showBurger ? 0 : 1 }}
                    ></motion.div>
                    <motion.div
                        style={{
                            backgroundColor:
                                location.pathname === "/" ||
                                location.pathname === "/contact"
                                    ? "#f2ebd8"
                                    : undefined,
                        }}
                        className='line'
                        animate={{
                            rotate: showBurger ? -45 : 0,
                            y: showBurger ? -7 : 0,
                        }}
                    ></motion.div>
                </div>
            </div>
            {showBurger && (
                <motion.div
                    className='burger-menu'
                    initial={{ x: "-100%" }}
                    animate={{ x: 0 }}
                    transition={{ type: "ease" }}
                >
                    <div className='links'>
                        <Link
                            to='/'
                            className={`link ${
                                location.pathname === "/" ? "active" : ""
                            }`}
                        >
                            <h2>Home</h2>
                        </Link>
                            <Link
                                to='/building'
                                className={`link ${
                                    location.pathname === "/building"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <h2>Building</h2>
                            </Link>
                        <Link
                            to='/availability'
                            className={`link ${
                                location.pathname === "/availability"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <h2>Availability</h2>
                        </Link>
                        <Link
                            to='/location'
                            className={`link ${
                                location.pathname === "/location"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <h2>Location</h2>
                        </Link>
                        <Link
                            to='/contact'
                            className={`link ${
                                location.pathname === "/contact" ? "active" : ""
                            }`}
                        >
                            <h2>Contact</h2>
                        </Link>
                    </div>
                    <div className='downloads'>
                        <p className='overline title'>DOWNLOAD</p>
                        <a className='btn-primary dl'>
                            <p>BROCHURE</p>
                            <img src='../images/download.svg' alt='download' />
                        </a>
                        <a className='btn-primary dl'>
                            <p>FLOORPLANS</p>
                            <img src='../images/download.svg' alt='download' />
                        </a>
                    </div>
                </motion.div>
            )}
            {location.pathname != "/" && (
                <div className='mobile-logo'>
                    <img src='../images/logo-green.svg' alt='logo' />
                </div>
            )}
        </>
    );
};

export default Nav;
