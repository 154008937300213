import { useLayoutEffect } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import './contact.scss';
const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <>
        <Navbar logoGreen={false} color="#f2ebd8" />
        <div className="contact-one">
            <h1 className='b'>
            To discover more, book a viewing with one of our agents
            </h1>
        </div>
        <Footer overline={""} copy={""} btn={""} link={""} top={false} />
        </>
    )
}

export default Contact;