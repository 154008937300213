import { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import Intro from "../../animations/intro/intro";
import { Link } from "react-router-dom";
import GalleryCopyPag from "../../components/gallerys/copypag/galleryCopyPag";
import { galleryImages } from "../../utils/home";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";

const Home: React.FC = () => {
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercent = (scrollTop / docHeight) * 2500;
        setBackgroundPosition(scrollPercent);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
            },
        },
    });

    useEffect(() => {
        document.cookie = "visited=true";
    }, []);
    const visited = document.cookie.includes("visited=true");

    return (
        <div
            className="hero"
            style={{ backgroundPositionY: `-${backgroundPosition}px` }}
        >
            <Navbar logoGreen={false} color="#f2ebd8" />
            {visited ? null : <Intro />}
            <div id="top" className="tile-one">
                <video autoPlay muted loop playsInline>
                    <source src="../videos/1.mp4" type="video/mp4" />
                </video>
                <div className="title-wrapper">
                    <p className="overline">42 WIGMORE STREET, W1</p>
                    <h1 className="b">
                    Wigmore Yard, a gem of a workspace, hidden behind the picturesque streets of Marylebone
                    </h1>
                </div>
                <div className="arrow-wrapper">
                    <h3 className="b">Discover Excellence Within</h3>
                    <img src="../images/arrow.svg" alt="arrow" />
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="tile-two-wrapper contain">
                    <div className="copy-wrapper">
                        <motion.p
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}
                            className="overline"
                        >
                            AVAILABLE Q1, 2025
                        </motion.p>
                        <motion.h2
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}
                            className="b"
                        >
                            This self-contained, 12,822 sq&nbsp;ft space is to become
                            the most sough-after, exclusive workspace for those
                            looking for a beautiful location
                        </motion.h2>
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}
                        >
                            <Link to="/building" className="btn-primary">
                                <motion.p>View the building</motion.p>
                            </Link>
                        </motion.div>
                    </div>
                    <div className="collection">
                        <div className="img-wrapper">                            
                        </div>
                        <div className="sec-images">
                            <div className="second"></div>
                            <div className="second"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <GalleryCopyPag images={galleryImages} />
            </div>
            <div className="tile-four-wrapper">
                <div className="copy-wrapper">
                    <motion.p
                        initial="initial"
                        whileInView="animate"
                        variants={variantX(0)}
                        className="overline"
                    >
                        INNOVATION AND EFFICIENCY
                    </motion.p>
                    <motion.h2
                        initial="initial"
                        whileInView="animate"
                        variants={variantX(0)}
                        className="b"
                    >
                        A sustainability flagship and exemplar project
                    </motion.h2>
                    <motion.div
                        initial="initial"
                        whileInView="animate"
                        variants={variantX(0)}
                    >
                        <Link
                            to="/building/#sustainability"
                            className="btn-primary sustainability"
                        >
                            <p>Sustainability</p>
                        </Link>
                    </motion.div>
                </div>
            </div>
            <Footer
                overline={"WIGMORE YARD, W1"}
                copy={"A premier workspace in the heart of London"}
                btn={"View the building"}
                link={"building"}
            />
        </div>
    );
};

export default Home;
