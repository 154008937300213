import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay"; // Import autoplay CSS
import "./freeScrollsBar.scss";

interface Slide {
    image: string;
    location: string;
    addOne: string;
    addTwo: string;
}

interface ScrollBarGalleryProps {
    slides: Slide[];
}

const FreeScrollBar = ({ slides }: ScrollBarGalleryProps) => {
    return (
        <SwiperComponent
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides={false}
            initialSlide={0}
            modules={[FreeMode, Navigation, Pagination, Autoplay]} // Include Autoplay module
            id='scrollbar-gallery'
            freeMode={true}
            pagination={{
                type: "progressbar",
                clickable: true,
            }}
            autoplay={{
                delay: 2000, // Slide delay in ms
                disableOnInteraction: false, // Continue autoplay after user interaction
            }}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <img src={slide.image} alt='slide' />
                    <div className='copy-wrapper'>
                        <img src='../images/tri.svg' alt='tri' />
                        <div className="copy">
                            <p>{slide.location}</p>
                            <p>{slide.addOne}</p>
                            <p>{slide.addTwo}</p>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </SwiperComponent>
    );
};
export default FreeScrollBar;
