export const ScrollsBarSlides = [
    {
        image: "../images/location/gallery/1.jpg",
        location: "Delamina",
        addOne: "56-58 Marylebone Lane",
        addTwo: "London W1U 2NX",
    },
    {
        image: "../images/location/gallery/2.jpg",
        location: "Daunt Brooks",
        addOne: "84 Marylebone High Street",
        addTwo: "London W1U 4QW",
    },
    {
        image: "../images/location/gallery/3.jpg",
        location: "The Botree Bar",
        addOne: "30 Marylebone Lane",
        addTwo: "London W1U 2DR",
    },
    {
        image: "../images/location/gallery/5.jpg",
        location: "Ole & Steen",
        addOne: "71-73 Wigmore Street",
        addTwo: "London W1U 1QA",
    },
    {
        image: "../images/location/gallery/4.jpg",
        location: "Selfridges",
        addOne: "400 Oxford Street, London W1A 1AB",
        addTwo: "London W1U 2NX",
    },
    {
        image: "../images/location/gallery/6.jpg",
        location: "Home",
        addOne: "79 Marylebone High Street, London W1U 5IZ",
        addTwo: "London W1U 2NX",
    },
];

export const occupiers = [
    "../images/location/occu/1.svg",
    "../images/location/occu/2.svg",
    "../images/location/occu/3.svg",
    "../images/location/occu/4.svg",
    "../images/location/occu/5.svg",
    "../images/location/occu/6.svg",
    "../images/location/occu/7.svg",
    "../images/location/occu/8.svg",
    "../images/location/occu/9.svg",
    "../images/location/occu/10.svg",
    "../images/location/occu/11.svg",
    "../images/location/occu/12.svg",
    "../images/location/occu/13.svg",
    "../images/location/occu/14.svg",
];
export const occupiers2 = [
    "../images/location/occu/15.svg",
    "../images/location/occu/16.svg",
    "../images/location/occu/17.svg",
    "../images/location/occu/18.svg",
    "../images/location/occu/19.svg",
    "../images/location/occu/20.svg",
    "../images/location/occu/21.svg",
    "../images/location/occu/22.svg",
    "../images/location/occu/23.svg",
    "../images/location/occu/24.svg",
    "../images/location/occu/25.svg",
    "../images/location/occu/26.svg",
    "../images/location/occu/27.svg",
    "../images/location/occu/28.svg",
];
