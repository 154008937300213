import { useEffect, useState } from "react";
import "./building.scss";
import GalleryStandard from "../../components/gallerys/standard/galleryStandard";
import { buildingImages } from "../../utils/building";
import Footer from "../../components/footer/footer";
import GreenSwiper from "../../components/gallerys/green/green";
import DetailGallery from "../../components/gallerys/detail/detailGallery";
import Navbar from "../../components/navbar/navbar";
import { delay, motion } from "framer-motion";

const Building = () => {


    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
                duration: 0.5,
                delay: x / 10,
            },
        },
    });

    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercent = (scrollTop / docHeight) * 2500;
        setBackgroundPosition(scrollPercent);
    };

    useEffect(() => {
        if(!window.location.hash.includes("#sustainability")){
            window.scrollTo(0, 0);
            console.log(window.location);

        }else{
            const sustainabilityElement = document.getElementById("sustainability");
            if (sustainabilityElement) {
                const { top } = sustainabilityElement.getBoundingClientRect();
                window.scrollTo({
                    top: window.scrollY + top,
                    behavior: "smooth",
                });
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    return (
        <div
            className="build-hero"
            style={{ backgroundPositionY: `-${backgroundPosition}px` }}
        >
            <Navbar logoGreen={true} color="#133c1e" />
            <div className="build-one">
                <h1 className="b">
                    Setting a new standard for premier workspaces in the heart
                    of London
                </h1>
            </div>
            <div className="contain-wrapper">
                <div className="build-two contain">
                    <GalleryStandard images={buildingImages} />
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-three contain">
                    {/*    <div className='row'>
                        <motion.h2
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(0)}
                        >
                            Thoughtfully designed and <br /> expertly engineered
                        </motion.h2>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/1.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                SIZE TOTAL
                            </motion.p>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='sqft-container'
                            >
                                <h1 className="b bigNumber">12,822</h1>
                                <p className='sqft'>sq ft</p>
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                            >
                                Floors from 1,063 sq ft - 3240 sq ft
                            </motion.p>
                        </div>
                    </div>
                    <div className='icon-row'>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/2.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                RECEPTION
                            </motion.p>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='sqft-container'
                            >
                                <h1 className="b number">1,163</h1>
                                <p className='sqft'>sq ft</p>
                            </motion.div>
                        </div>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/3.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                COURTYARD
                            </motion.p>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='sqft-container'
                            >
                                <h1 className="b number">1710</h1>
                                <p className='sqft'>sq ft</p>
                            </motion.div>
                        </div>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/4.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                TERRACES
                            </motion.p>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='sqft-container'
                            >
                                <h1 className="b number">1150</h1>
                                <p className='sqft'>sq ft</p>
                            </motion.div>
                        </div>
                    </div>
                    <div className='icon-row'>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/5.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'                                
                            >
                                EPC: A (TARGETED)
                            </motion.p>
                            <motion.h3
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                            >
                                Excellent building efficiency
                            </motion.h3>
                        </div>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/6.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                OCCUPANCY
                            </motion.p>
                            <motion.h3
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                            >
                                M&E design ratio: 1:8
                            </motion.h3>
                        </div>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/7.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                FACILITIES
                            </motion.p>
                            <motion.h3
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                            >
                                56 cycle s&#8203;paces
                                <br />5 showers
                                <br />
                                86 lockers                                
                            </motion.h3>
                        </div>
                        <div className='number-wrapper'>
                            <motion.div
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='icon-wrapper'
                            >
                                <img
                                    src='../images/building/icons/8.svg'
                                    alt='icon1'
                                />
                            </motion.div>
                            <motion.p
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                                className='overline'
                            >
                                EXPOSED VRF A/C
                            </motion.p>
                            <motion.h3
                                initial='initial'
                                whileInView='animate'
                                variants={variantX(0)}
                            >
                                On all floors
                            </motion.h3>
                        </div>
                    </div> */}
                    <div className="copy-wrapper">
                        <motion.h2
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}                            
                        >
                            Thoughtfully designed and expertly engineered
                        </motion.h2>
                    </div>
                    <div className="bento">
                        <div className="row-one">
                            <motion.div
                                variants={variantX(0)}
                                initial="initial"
                                whileInView="animate"
                                className="box"
                                viewport={{once: true}}
                            >
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/1.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">SIZE TOTAL</p>
                                </div>
                                <p>Floors from 1,063 sq ft - 3,240 sq ft</p>
                                <h1>
                                    12,822<span>sq ft</span>
                                </h1>
                            </motion.div>
                            <motion.div
                                variants={variantX(1)}
                                initial="initial"
                                whileInView="animate"
                                className="img"
                                viewport={{once: true}}
                            ></motion.div>
                        </div>
                        <div className="row-two">
                            <motion.div variants={variantX(0)} viewport={{once: true}}  initial="initial"
                            whileInView="animate" className="box">
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/2.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">RECEPTION</p>
                                </div>

                                <h1>
                                    1,163<span>sq ft</span>
                                </h1>
                            </motion.div>
                            <motion.div variants={variantX(0)} viewport={{once: true}}  initial="initial"
                            whileInView="animate" className="box">
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/3.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">COURTYARD</p>
                                </div>

                                <h1>
                                    1,710<span>sq ft</span>
                                </h1>
                            </motion.div>
                            <motion.div variants={variantX(1)} viewport={{once: true}}  initial="initial"
                            whileInView="animate" className="box">
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/4.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">TERRACE</p>
                                </div>

                                <h1>
                                    1,150<span>sq ft</span>
                                </h1>
                            </motion.div>
                        </div>
                        <div className="row-three">
                            <motion.div variants={variantX(0)} viewport={{once: true}}  initial="initial"
                            whileInView="animate" className="box">
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/5.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">
                                        EPC: A <br /> (TARGETED)
                                    </p>
                                </div>
                                <h2>Excellent building efficiency</h2>
                            </motion.div>
                            <motion.div variants={variantX(0)} viewport={{once: true}}  initial="initial"
                            whileInView="animate" className="box">
                                {" "}
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/6.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">OCCUPANCY</p>
                                </div>
                                <h2>M&E design ratio: 1:8</h2>
                            </motion.div>
                            <motion.div variants={variantX(1)}  initial="initial"
                            whileInView="animate" viewport={{once: true}} className="box">
                                {" "}
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/7.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">FACILITIES</p>
                                </div>
                                <h2>
                                    56 cycle <span className="nowrap">spaces</span>, 5 showers, 86
                                    lockers
                                </h2>
                            </motion.div>
                            <motion.div variants={variantX(2)}  initial="initial"
                            whileInView="animate" viewport={{once: true}} className="box">
                                {" "}
                                <div className="icon-wrapper">
                                    <img
                                        src="../images/building/icons/8.svg"
                                        alt="icon1"
                                    />
                                    <p className="overline">EXPOSED VRF A/C</p>
                                </div>
                                <h2>On all floors</h2>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-four contain">
                    <div className="copy-wrapper">
                        <motion.h3
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}
                        >
                            Start your day refreshed
                        </motion.h3>
                        <motion.p
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(2)}
                        >
                            Begin your day feeling refreshed in the stunning
                            facilities located at the the lower ground floor.
                        </motion.p>
                    </div>
                    <div className="img-wrapper">
                        <img src="../images/building/1.jpg" />
                        <p className="img-caption">CGI. Indicative only</p>
                    </div>
                </div>
            </div>

            <div className="build-five">
                <div className="contain-wrapper">
                    <div className="contain" id="sustainability">
                        <h2>
                            Wigmore Yard will be abrdn's sustainability flagship
                            and exemplar project providing:
                        </h2>
                    </div>
                </div>
                <div className="contain-wrapper">

                <div className="masonry contain">
                    <div className="col">
                        <motion.div  variants={variantX(0)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Net Zero.svg"
                                alt="ac"
                            />
                            <h3>Net Zero Operational Readiness</h3>
                        </motion.div>
                        <motion.div  variants={variantX(0)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Sustainable materials.svg"
                                alt="ac"
                            />
                            <h3>Sustainable and efficient materials</h3>
                            <ul>
                                <li>
                                    <p>
                                        Timber for structure and infill reducing
                                        upfront embodied carbon emissions
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Reclaimed raised access flooring
                                        reducing upfront embodied carbon
                                        emissions
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Locally sourced materials with circular
                                        economy principles{" "}
                                    </p>
                                </li>
                            </ul>
                        </motion.div>
                        <motion.div  variants={variantX(0)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Sustainable Certification.svg"
                                alt="ac"
                            />
                            <h3>Sustainable Certification Market Leader</h3>
                            <p>
                                Certifications (targeted pending completion): 
                            </p>
                            <ul>
                                <li>
                                    <p>BREEAM (Outstanding) </p>
                                </li>
                                <li>
                                    <p>WELL (Gold) </p>
                                </li>
                                <li>
                                    <p>Wired Score – GOLD</p>
                                </li>
                                <li>
                                    <p>Smart Score – GOLD</p>
                                </li>
                                <li>
                                    <p>Nabers UK 4.5* Well Enabled</p>
                                </li>
                                <li>
                                    <p>
                                        Reset (Regenerative Ecological
                                        Sustainable Ecosystem Technology)
                                    </p>
                                </li>
                                <li>
                                    <p>Active Score</p>
                                </li>
                                <li>
                                    <p>Target EPC A</p>
                                </li>
                            </ul>
                        </motion.div>
                    </div>
                    <div className="col">
                        <motion.div  variants={variantX(1)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Carbon Performance.svg"
                                alt="ac"
                            />
                            <h3>Exemplar Embodied Carbon Performance</h3>
                            <p>
                                Targeting an Embodied Carbon range of A - A+
                                (150-300 kg/CO2e/m2)
                            </p>
                        </motion.div>
                        <motion.div  variants={variantX(1)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Biodiversity.svg"
                                alt="ac"
                            />
                            <h3>
                                Improved biodiversity and urban greening factor
                            </h3>
                            <ul>
                                <li>
                                    <p>
                                        Intensive greening providing significant
                                        biodiversity enhancement gain while
                                        linking to the ‘Wild West End’
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Enhanced courtyard and tunnel
                                        landscaping improving ecological value
                                        and contributing to air quality{" "}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Accessible courtyard & terrace areas
                                        with added greening{" "}
                                    </p>
                                </li>
                            </ul>
                        </motion.div>
                        <motion.div  variants={variantX(1)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Solar powered.svg"
                                alt="ac"
                            />
                            <h3>Solar powered</h3>
                            <p>
                                Photovoltaic panels at roof level to generate
                                additional electricity
                            </p>
                        </motion.div>
                    </div>
                    <div className="col">
                        <motion.div  variants={variantX(2)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Carbon monitoring.svg"
                                alt="ac"
                            />
                            <h3>
                                Innovative in-use energy and carbon monitoring
                            </h3>
                            <p>
                                Smart mixed-mode ventilation strategy for
                                balancing user comfort and energy performance
                                through openable windows and link to BMS,
                                minimising the need for plant and machinery
                            </p>
                        </motion.div>
                        <motion.div  variants={variantX(2)}
                                initial="initial"
                                viewport={{once: true}}
                                whileInView="animate" className="cell">
                            <img
                                src="../images/building/esg/Enhanced Wellbeing.svg"
                                alt="ac"
                            />
                            <h3>
                                Enhanced Wellbeing – a smart connected building
                            </h3>
                            <ul>
                                <li>
                                    <p>
                                        Smart building app to connect building
                                        and users
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        NABERS ready to monitor the performance
                                        of the building{" "}
                                    </p>
                                </li>
                            </ul>
                        </motion.div>
                        <motion.div  variants={variantX(2)}
                                initial="initial"
                                whileInView="animate"
                                viewport={{once: true}}
                                className="cell">
                            <img
                                src="../images/building/esg/Waste Diverted.svg"
                                alt="ac"
                            />
                            <h3>
                                98% construction waste diverted from landfill
                            </h3>
                        </motion.div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-six contain">
                    <motion.h2
                        initial="initial"
                        whileInView="animate"
                        variants={variantX(0)}
                    >
                        Standard Setting for creditations
                    </motion.h2>
                    <div className="logo-wrapper">
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(0)}
                            className="logo"
                        >
                            <div className="img-wrapper">
                                <img
                                    src="../images/building/logos/1.svg"
                                    alt="logo"
                                />
                            </div>
                            <h3 className="b">
                                BREEAM* <br /> Outstanding
                            </h3>
                            <p>
                                BREEAM is the world's leading sustainability
                                assessment method for infrastructure and
                                buildings
                            </p>
                            <br />
                            <small>*Targeting</small>
                        </motion.div>
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(2)}
                            className="logo"
                        >
                            <div className="img-wrapper">
                                <img
                                    src="../images/building/logos/2.svg"
                                    alt="logo"
                                />
                            </div>
                            <h3 className="b">
                                Nabers 4.5* <br /> Well Enabled
                            </h3>
                            <p>
                                Tracking and measuring actual operational
                                efficiency
                            </p>
                        </motion.div>
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(4)}
                            className="logo"
                        >
                            <div className="img-wrapper">
                                <img
                                    src="../images/building/logos/3.svg"
                                    alt="logo"
                                />
                            </div>
                            <h3 className="b">
                                WireScore* <br /> Gold
                            </h3>
                            <p>
                                Digital connectivity and infrastructure enabled
                            </p>
                        </motion.div>
                        <motion.div
                            initial="initial"
                            whileInView="animate"
                            variants={variantX(6)}
                            className="logo"
                        >
                            <div className="img-wrapper">
                                <img
                                    src="../images/building/logos/4.svg"
                                    alt="logo"
                                />
                            </div>
                            <h3 className="b">
                                EPC* <br /> Rating A
                            </h3>
                            <p>
                                High energy efficiency lowers emissions and
                                costs
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="build-seven contain">
                    <h2 className="b">Attention to detail</h2>
                    <DetailGallery
                        slides={[
                            {
                                image: "../images/building/gallery/detail/1.jpg",
                                description: "Torcello honed terrazzo ",
                            },                    
                            {
                                image: "../images/building/gallery/detail/6.jpg",
                                description: "External planting",
                            },
                            {
                                image: "../images/building/gallery/detail/3.jpg",
                                description: "Lido terrazzo",
                            },
                            {
                                image: "../images/building/gallery/detail/5.JPEG",
                                description: "Oak timber flooring",
                            },
                            {
                                image: "../images/building/gallery/detail/7.png",
                                description: "Timber cladding",
                            },
                        ]}
                    />
                </div>
            </div>
            <Footer
                overline={"AVAILABILITY"}
                copy={
                    "An exclusive workspace for those looking for offices in the heart of Marylebone"
                }
                btn={"View Availability"}
                link={"availability"}
            />
        </div>
    );
};

export default Building;
