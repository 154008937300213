import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import {
    Autoplay,   
    Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./galleryCopyPag.scss";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

interface GalleryImage {
    image: string;
    caption: string;
}

interface SwiperProps {
    images: GalleryImage[];
}

const GalleryCopyPag = ({ images }: SwiperProps) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const swiperRef = useRef<any>(null);

    const handleSlideChange = (swiper: any) => {
        console.log(swiper.activeIndex);
        if(swiper.activeIndex === 6) {
            setActiveSlide(1);
        }else{
            setActiveSlide(swiper.activeIndex);
        }
    };

    const handleCaptionClick = (index: number) => {
        if (swiperRef.current) {
            setActiveSlide(index);
            swiperRef.current.slideTo(index);
        }
    };

    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
            },
        },
    });

    return (
        <div className='tile-three-wrapper contain'>
            <SwiperComponent
                pagination={{ clickable: true, el: ".pagination-progress" }}
                modules={[Pagination, Autoplay]}
                loop={false}
                centeredSlides={true}
                slidesPerView={1}
                initialSlide={0}
                id='hero-swiper'
                autoHeight={true}
                onSlideChange={handleSlideChange}
                onSwiper={(swiper: any) => {
                    swiperRef.current = swiper;
                }}
                autoplay={{ delay: 2000 }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className='background-image'
                            style={{ backgroundImage: `url(${image.image})` }}
                        />
                    </SwiperSlide>
                ))}
                <div className='pagination-progress'></div>
            </SwiperComponent>
            <div className='copy-wrapper'>
                <motion.p
                    initial='initial'
                    whileInView='animate'
                    variants={variantX(1)}
                    className='overline'
                >
                    wigmore yard
                </motion.p>
                <div className='copy-container'>
                    {images.map((image, index) => (
                        <motion.h2
                            initial='initial'
                            whileInView='animate'
                            variants={variantX(1)}
                            key={index}
                            className={index=== activeSlide ? "active b" : "b"}
                            onClick={() => handleCaptionClick(index )}
                        >
                            {image.caption}
                        </motion.h2>
                    ))}
                </div>
                <motion.div
                    initial='initial'
                    whileInView='animate'
                    variants={variantX(1)}
                >
                    <Link to='/location' className='btn-primary'>
                        <p>Explore the local area</p>
                    </Link>
                </motion.div>
            </div>
        </div>
    );
};

export default GalleryCopyPag;
