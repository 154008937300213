export const floorplanData  = [
    {
        title: "Lower Ground Floor",
        btn: "Lower Ground",
        table: [
            {
                unit: "Office",
                sqFt: "926 sq ft",
                sqM: "86 sq m",
            },
            {
                unit: "Storage",
                sqFt: "150 sq ft",
                sqM: "14 sq m",
            },
        ],
    },
    {
        title: "Ground Floor",
        btn: "Ground",
        table: [
            {
                unit: "Total",
                sqFt: "1,044 sq ft",
                sqM: "97 sq m",
            },
            {
                unit: "Reception",
                sqFt: "1,163 sq ft",
                sqM: "108 sq m",
            },
        ],
    },
    {
        title: "First Floor",
        btn: "First",
        table: [
            {
                unit: "Total",
                sqFt: "3,240 sq ft",
                sqM: "301 sq m",
            },
        ],
    },
    {
        title: "Second Floor",
        btn: "Second",
        table: [
            {
                unit: "Total",
                sqFt: "3,186 sq ft",
                sqM: "296 sq m",
            },
        ],
    },
    {
        title: "Third Floor",
        btn: "Third",
        table: [
            {
                unit: "Total",
                sqFt: "2,190 sq ft",
                sqM: "203 sq m",
            },
            {
                unit: "Terrace",
                sqFt: "581 sq ft",
                sqM: "54 sq m",
            },
        ],
    },
    {
        title: "Fourth Floor",
        btn: "Fourth",
        table: [
            {
                unit: "Total",
                sqFt: "1,063 sq ft",
                sqM: "99 sq m",
            },
            {
                unit: "Terrace",
                sqFt: "570 sq ft",
                sqM: "53 sq m",
            },
        ],
    },
];