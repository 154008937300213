import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import "./location.scss";
import FreeScrollBar from "../../components/gallerys/freeScrollBar/freeScrollsBar";
import { occupiers, occupiers2, ScrollsBarSlides } from "../../utils/location";
import TubeStation from "../../components/tubeStation/tubeStation";
import { motion } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import KeyElement from "../../components/diamond/Keyelem";

const Location = () => {
    const [excellence, setExcellence] = useState(0);
    const variantX = (x: number) => ({
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                effect: "ease",
                delay: x / 10,
            },
        },
    });
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercent = (scrollTop / docHeight) * 2500;
        setBackgroundPosition(scrollPercent);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            className="location-hero"
            style={{ backgroundPositionY: `-${backgroundPosition}px` }}
        >
            <Navbar logoGreen={true} color="#133c1e" />
            <div className="location-one">
                <h1 className="b">
                    Exceptional travel links, serene parks, and a vibrant array
                    of restaurants and shops
                </h1>
            </div>
            <div className="contain-wrapper">
                <div className="location-two contain">
                    <div className="copy-image">
                        <div className="copy">
                            <motion.h2
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(0)}
                                className="b"
                            >
                                Excellence all round
                            </motion.h2>
                            <motion.p
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(0)}
                            >
                                This charming neighbourhood has artisanal
                                bakeries and chic boutiques as well as hidden
                                gems and historic institutions. The plethora of
                                parks provide an urban oasis when a rest is
                                needed from the buzz on the many cobbled
                                streets.
                            </motion.p>
                        </div>
                        <div className="img-wrapper">
                            <motion.img
                                initial="initial"
                                whileInView="animate"
                                src="../images/location/1.jpg"
                                alt="hyde park"
                            />
                            <p className="img-caption">Hyde Park</p>
                        </div>
                    </div>
                    <FreeScrollBar slides={ScrollsBarSlides} />
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="location-three contain">
                    <div className="title-wrapper">
                        <h2 className="b">Finding excellence</h2>
                        <div className="btns-wrapper">
                            <a
                                className={`btn-pill ${
                                    excellence === 0 ? "active" : ""
                                }`}
                                onClick={() => setExcellence(0)}
                            >
                                {" "}
                                <p className="overline">CONNECTIONS</p>
                            </a>
                            <a
                                className={`btn-pill ${
                                    excellence === 1 ? "active" : ""
                                }`}
                                onClick={() => setExcellence(1)}
                            >
                                <p className="overline">RETAIL</p>
                            </a>
                            <a
                                className={`btn-pill ${
                                    excellence === 2 ? "active" : ""
                                }`}
                                onClick={() => setExcellence(2)}
                            >
                                <p className="overline">HOSPITALITY</p>
                            </a>
                            <a
                                className={`btn-pill ${
                                    excellence === 3 ? "active" : ""
                                }`}
                                onClick={() => setExcellence(3)}
                            >
                                <p className="overline">HOTELS</p>
                            </a>
                            <a
                                className={`btn-pill ${
                                    excellence === 4 ? "active" : ""
                                }`}
                                onClick={() => setExcellence(4)}
                            >
                                <p className="overline">OCCUPIERS</p>
                            </a>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        {excellence === 0 && (
                            <div className="lines">
                                <TubeStation
                                    stationName={"Bond Street"}
                                    walkingDistance={"2"}
                                    tubeLines={["liz", "central", "jub"]}
                                />
                                <TubeStation
                                    stationName={"Oxford Circus"}
                                    walkingDistance={"5"}
                                    tubeLines={["bakerloo", "central", "vic"]}
                                />
                                <TubeStation
                                    stationName={"Tottenham Court Road"}
                                    walkingDistance={"15"}
                                    tubeLines={["liz", "central", "north"]}
                                />
                                <TubeStation
                                    stationName={"Marble Arch"}
                                    walkingDistance={"12"}
                                    tubeLines={["central"]}
                                />
                                <TubeStation
                                    stationName={"Baker Street"}
                                    walkingDistance={"14"}
                                    tubeLines={[
                                        "Bakerloo",
                                        "Circle",
                                        "hammer",
                                        "jub",
                                        "Metro",
                                    ]}
                                />
                                <TubeStation
                                    stationName={"Marylebone Station"}
                                    walkingDistance={"20"}
                                    tubeLines={["Bakerloo"]}
                                />
                            </div>
                        )}
                        {excellence === 1 && (
                            <div className="key dark">
                             
                             
                                    <KeyElement number={1} name="John Lewis" fill="#c0dde9" />
                                <KeyElement number={2} name="Selfridges" fill="#c0dde9" />
                                <KeyElement number={3} name="M&S" fill="#c0dde9" />
                             
                            </div>
                        )}
                        {excellence === 2 && (
                            <div className="key">
                                <KeyElement number={1} name="The Ivy" />
                                <KeyElement number={2} name="Carlotta" />
                                <KeyElement number={3} name="St John" />
                                <KeyElement number={4} name="Delamina" />
                                <KeyElement number={5} name="Home House" />
                                <KeyElement number={6} name="Lina Store" />
                                <KeyElement number={7} name="Cavita" />
                                <KeyElement number={8} name="Harry’s Bar" />
                                <KeyElement number={9} name="Ole & Steen" />
                                <KeyElement number={10} name="Bossa" />
                                <KeyElement
                                    number={11}
                                    name="Comptoir Libanais"
                                />
                            </div>
                        )}
                        {excellence === 3 && (
                            <div className="key">
                                <KeyElement
                                    number={1}
                                    name="Langham Hotel"
                                    fill="#a67c52"
                                />
                                <KeyElement
                                    number={2}
                                    name="The Wigmore"
                                    fill="#a67c52"
                                />
                                <KeyElement
                                    number={3}
                                    name="Botree Hotel"
                                    fill="#a67c52"
                                />
                            </div>
                        )}
                        {excellence === 4 && (
                            <div className="key">
                                <KeyElement
                                    number={1}
                                    name="BGO"
                                    fill="#00000"
                                />
                                <KeyElement
                                    number={2}
                                    name="British Land"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={3}
                                    name="CBRE"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={4}
                                    name="Invesco"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={5}
                                    name="Bridgepoint"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={6}
                                    name="Coca-Cola"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={7}
                                    name="CLI Dartriver"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={8}
                                    name="BC Partners "
                                    fill="#00000"                                />
                                <KeyElement
                                    number={9}
                                    name="Knight Frank"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={10}
                                    name="Lazard"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={11}
                                    name="Pimco"
                                    fill="#00000"                                />
                                <KeyElement
                                    number={12}
                                    name="Moelis"
                                    fill="#00000"                                />

                                                                                      
                            </div>
                        )}

                        <img
                            src={`../images/location/map${excellence + 1}.svg`}
                            alt="map"
                        />
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="location-five contain">
                    <div className="title-wrapper">
                        <h2 className="b">
                            National and international connections
                        </h2>
                    </div>
                    <div className="map-container">
                        <img
                            className="elizabeth-line"
                            src="../images/location/elizabeth.png"
                        />
                    </div>
                </div>
            </div>
            <div
                className="contain-wrapper swans"
                style={{ backgroundImage: "url(../images/location/2.jpg)" }}
            >
                <div className="location-four contain">
                    <div className="background">
                        <h1 className="b">Discover Nature</h1>
                        <h2 className="b">
                            With Cavendish Square, Hanover Square, Regent’s Park
                            and Hyde Park on your doorstep, there is no shortage
                            of green space to offer a serene atmosphere and a
                            chance to find solace in nature’s embrace in the
                            bustling city.
                        </h2>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="location-six contain">
                    <div className="image-wrapper">
                        <img src="../images/location/3.jpg" alt="location" />
                        <div className="copy-wrapper">
                            <img src="../images/tri.svg" />
                            <p>Cavendish Square</p>
                        </div>
                    </div>
                    <div className="image-wrapper">
                        <img src="../images/location/4.jpg" alt="location" />
                        <div className="copy-wrapper">
                            <img src="../images/tri.svg" />
                            <p>Portman Square</p>
                        </div>
                    </div>
                    <div className="image-wrapper">
                        <img src="../images/location/5.jpg" alt="location" />
                        <div className="copy-wrapper">
                            <img src="../images/tri.svg" />
                            <p>Regent's Park</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="location-seven contain">
                    <motion.h2
                        initial="initial"
                        whileInView="animate"
                        variants={variantX(0)}
                        className="b"
                    >
                        Occupiers and amenities in the neighbourhood
                    </motion.h2>
                    <div className="occ-wrapper">
                        {occupiers.map((occ, i) => (
                            <motion.div
                                key={i}
                                className="occ"
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(i)}
                                viewport={{ once: true }}
                            >
                                <img src={occ} alt={"occupier"} />
                            </motion.div>
                        ))}
                    </div>
                    <div className="occ-wrapper">
                        {occupiers2.map((occ, i) => (
                            <motion.div
                                key={i}
                                className="occ"
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(i)}
                                viewport={{ once: true }}
                            >
                                <img src={occ} alt={"occupier"} />
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer
                overline={"GET IN TOUCH"}
                copy={"Book a viewing with one of our agents"}
                btn={"Contact"}
                link={"contact"}
            />
        </div>
    );
};
export default Location;
