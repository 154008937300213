import { motion } from "framer-motion";
import path from "path";

interface tubeProps {
    x: number;
}

const TubeAnimated = ({x}: tubeProps) => {
    const circleVariants = (x: number) => ({
        hidden: { pathLength: 0},
        visible: {
            pathLength: 1,            
            transition: {
                duration: 0.75,
                ease: "easeInOut",
                delay: x
            },
        },
    });

    const rectVariants = (x: number) => ({
        hidden: { pathLength: 0, width: 0 },
        visible: {
            width: "100%",
            pathLength: 1,
            transition: {
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.8 + x,
            },
        },
    });

    return (
        <svg
            viewBox='0 0 24 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className="tube-svg"
            width='24'
            height='20'
        >
            <circle
                //motion.circle
                cx='12.0004'
                cy='9.6'
                r='7.9'
                stroke='#DA4435'
                strokeWidth='3.4'
                //variants={circleVariants(x)}
                //initial='hidden'
                //whileInView='visible'
            />
            <rect
                //motion.rect
                y='7.2002'
                width='24'
                height='4.8'
                fill='#243E8E'
                //variants={rectVariants(x)}
                //initial='hidden'
                //whileInView='visible'
            />
        </svg>
    );
};

export default TubeAnimated;
