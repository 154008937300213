import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './detailGallery.scss';

interface Slide {
    image: string;
    description: string;
}

interface DetailGalleryProps {
    slides: Slide[];
}

const DetailGallery = ({ slides }: DetailGalleryProps) => {
    return (
        <SwiperComponent
            spaceBetween={10}
            centeredSlides={false}
            loop={true}
            initialSlide={0}
            modules={[Autoplay, Navigation, Pagination]}
            id='detail-swiper'
            pagination={{
                el: ".pagination-progress",
            }}
            slidesPerView={'auto'}
            freeMode={true}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <img src={slide.image} alt='slide' />
                    <p>{slide.description}</p>
                </SwiperSlide>
            ))}
            <div className='pagination-progress'></div>
        </SwiperComponent>
    );
};

export default DetailGallery;