const DiamondSVG = ({fill = '#f0a237' }: { fill?: string }) => (
    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.92 57.01"
        width="33"
        height="57"
    >
        <defs>
            <style>
                {`
                    .cls-5 {
                        fill: ${fill};
                        stroke-width: 0px;
                    }
                `}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <path
                className="cls-5"
                d="M16.46,57.01L0,28.51,16.46,0l16.46,28.51-16.46,28.51Z"
            />
        </g>
    </svg>
);

const KeyElement = ({ number, name, fill = '#f0a237' }: { number: number, name: string, fill?: string }) => (
    <div className="key-el">
        <div className="diamond yellow">
            <p>{number}</p>
            <DiamondSVG fill={fill} />
        </div>
        <p>{name}</p>
    </div>
);
export default KeyElement
